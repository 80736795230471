import React from 'react';
import './Carousel.css';

import arrowImg from '../img/arrow.svg';

export default class Carousel extends React.Component {
    getWrappingList = () => {
        let { items, selected } = this.props;
        if (!items || items.length === 0) {
            return null
        }
        if (this.props.selected) {
            selected = this.props.selected;
        }
        let newList = [];
        const len = items.length;
        const idList = items.map(item => item.id);
        const activeIndex = idList.indexOf(selected.id);
        for (let i=-4; i<=4; i++) {
            let index = activeIndex + i;
            while (index < 0) {
                index += len;
            }
            while (index >= len) {
                index -= len;
            }
            const item = items[index];
            newList.push(item)
        }
        return newList;
    }

    render = () => {
        const wrappingList = this.getWrappingList();
        if (!wrappingList) {
            return "";
        }
        const className = "itemList" + (this.props.className? " "+this.props.className: "");
        return (
           <div className="itemList-Container">
               <img src={ arrowImg } alt="" className="arrow" />
                <div className={className}>
                   <span className="item mooore">{wrappingList[0].name}</span>
                   <span className="item moore">{wrappingList[1].name}</span>
                   <span className="item more">{wrappingList[2].name}</span>
                   <span className="item next">{wrappingList[3].name}</span>
                   <span className="item active">{wrappingList[4].name}</span>
                   <span className="item next">{wrappingList[5].name}</span>
                   <span className="item more">{wrappingList[6].name}</span>
                   <span className="item moore">{wrappingList[7].name}</span>
                   <span className="item mooore">{wrappingList[8].name}</span>
                </div>
           </div>
        )
    }
}
