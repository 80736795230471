export default class WebsocketHandler {
    constructor(stateHandler) {
        this.stateHandler = stateHandler;
        let baseURL = 'ws://localhost:8000';
        const isProduction = process.env.NODE_ENV === "production";
        if (isProduction) {
            const proto = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
            baseURL = proto + '//' + window.location.host;
        }
        this.webSocketURL = baseURL + '/ws';
    }

    start = () => {
        const oldId = localStorage.getItem('playerId');
        const oldName = localStorage.getItem('playerName');
        const args = [];
        const roomId = window.location.pathname.split('/')[1];
        this.connectionURL = this.webSocketURL;
        if (oldId) {
            args.push("playerId="+oldId);
        }
        if (oldName) {
            args.push("playerName="+oldName);
        }
        if (roomId) {
            args.push("roomId="+roomId)
        }
        if (args.length) {
            this.connectionURL += "?" + args[0];
            if (args.length > 1) {
                this.connectionURL += "&" + args.slice(1).join('&');
            }
        }
        if (roomId) {
            this.connection = new WebSocket(this.connectionURL);
            this.connection.onmessage = msg => this.recieve(msg);
            this.stateHandler.setState({ wsActive: true})
        }
        this.stateHandler.setState({ wsActive: false })
    }

    recieve = msg => {
        const data = JSON.parse(msg.data);
        if (data.type === undefined) {
            console.error('recieved ws response with no type:');
            console.error(data);
        } else if (data.type === 'welcome') {
            delete data.type;
            this.handleWelcome(data);
        } else if (data.type === 'state_update') {
            delete data.type;
            this.updateState(data);
        } else if (data.type === 'show_dice') {
            this.updateState(data);
        } else if (data.type === 'awesome_select') {
            this.stateHandler.setState({'awesomeSelect': data.id});
        } else if (data.type === 'actionRequested') {
            const event = new CustomEvent('actionRequested');
            document.dispatchEvent(event);
        } else {
            console.log(data);
        }
    }

    handleWelcome = data => {
        this.id = data.playerId;
        localStorage.setItem('playerId', data.playerId);
        localStorage.setItem('playerName', data.playerName);
        this.stateHandler.setState(data)
        let ready = new CustomEvent('WebSocketReady', {});
        document.dispatchEvent(ready);
    }

    updateState = data => {
        for (let player of data.players) {
            if (player.id === this.id) {
                this.stateHandler.setState({
                    playerId: player.id,
                    playerName: player.name
                })
                break;
            }
        }
        this.stateHandler.setState(data);
    }

    rollDice = () => {
        this.send({type: 'rollDice'});
    }

    submitRoll = (dices, withAir) => {
        this.send({type: 'submitRoll', dices: dices, withAir: withAir});
    }

    submitReRoll = () => {
        this.send({type: 'submitReRoll'});
    }

    showDice = () => {
        this.send({type: 'showDice'});
    }

    confirmDrink = () => {
        this.send({type: 'confirmDrink'});
    }

    higher = () => {
        this.send({type: 'higher'});
    }

    awesomeDrink = (id) => {
        if (this.stateHandler.state.playerId === this.stateHandler.state.activePlayer.id) {
            this.send({type: 'awesomeDrink', id: id});
        }
    }

    awesomeSelect = (index) => {
        let player_id = this.stateHandler.state.players[index];
        if (this.stateHandler.state.playerId === this.stateHandler.state.activePlayer.id) {
            this.send({type: 'awesomeSelect', id: player_id});
        }
    }

    requestAction = (other) => {
        this.send({type: 'requestAction', other: other });
    }

    send = msg => {
        this.connection.send(JSON.stringify(msg));
    }
}
