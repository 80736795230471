import React from 'react';
import { globalState } from '../state';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import Carousel from '../components/Carousel';
import Button from '../components/Button';
import DicePresentation from '../components/DicePresentation';

export default class AwesomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlayer: null
        }
    }

    componentDidMount() {
        this.setState({ selectedPlayer: this.context.activePlayer });
    }

    changePlayer = (number) => {
        if (!this.props.active) {
            return
        }
        const { players } = this.context;
        const len = players.length;
        const idList = players.map(player => player.id);
        const activeIndex = idList.indexOf(this.state.selectedPlayer.id);
        let newIndex = activeIndex + number;
        if (newIndex < 0) {
            newIndex += len;
        }
        if (newIndex >= len) {
            newIndex -= len;
        }
        this.setState({ selectedPlayer: players[newIndex] });
        this.context.wsHandler.awesomeSelect(newIndex);
    }

    componentDidUpdate = () => {
        const { players, awesomeSelect } = this.context;
        if (awesomeSelect && !this.props.active) {
            const player = players.find(player => player.id === awesomeSelect.id);
            if (player && player !== this.state.selectedPlayer) {
                this.setState({ selectedPlayer: player });
            }
        }
    }

    renderActiveField = () => {
        if (!this.props.active) {
            return (
                <div className="activeField">
                    <p>{this.context.activePlayer.name} sucht gerade den mit dem höchsten Bedarf</p>
                    <p>Oben siehst du wen er gerade im Visier hat</p>
                </div>
            )
        }
        return (
            <div className="activeField">
                <ButtonLine>
                    <Button
                      size="small"
                      color="light"
                      onClick={ () => this.changePlayer(-1)}>
                        Vorheriger
                    </Button>
                    <Button
                      size="small"
                      color="light"
                      onClick={ () => this.changePlayer(1)}>
                        Nächster
                    </Button>
                </ButtonLine>
                <Button
                  style={{"margin": "4em 1.5em", "width": "calc(100% - 3em)"}}
                  onClick={ () => this.context.wsHandler.awesomeDrink(this.state.selectedPlayer.id) }>
                    { this.state.selectedPlayer.name } muss saufen
                </Button>
            </div>
        )
    }

    render() {
        const { players, activePlayer } = this.context;
        if (this.state.selectedPlayer === null) {
            return '';
        }
        return (
            <View>
                <MessageArea>
                    <h1>{ this.props.active? "Du hast" : activePlayer.name + " hat"} ein Mäxchen!!!</h1>
                </MessageArea>
                <InfoArea>
                    <DicePresentation dices={[2, 1]} />
                </InfoArea>
                <PlayArea>
                    <Carousel items={players} selected={this.state.selectedPlayer} className="big"/>
                    { this.renderActiveField() }
                </PlayArea>
            </View>
        )
    }
}

AwesomeView.contextType = globalState;
