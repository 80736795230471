import Shaker from './Shaker';

import './ViewParts.css';

export const View = props => {
    return (
        <div className={ props.className? props.className + " view": "view"}>
            { props.children }
        </div>
    )
}

export const PlayArea = props => {
    return (
        <div className="view-play">
            <Shaker shake={ props.shake? true: false }>
                { props.children }
            </Shaker>
        </div>
    )
}

export const ButtonLine = props => {
    return (
        <div className="view-button-line">
            { props.children }
        </div>
    )
}

export const InfoArea = props => {
    return (
        <div className="view-info">
            <div className="view-info-content">
                { props.children }
            </div>
        </div>
    )
}

export const MessageArea = props => {
    return (
        <div className="view-message">
            <div className="view-message-content">
                { props.children }
            </div>
        </div>
    )
}

export default View;
