import React from 'react';
import { globalState } from '../state';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';
import DiceSelect from '../components/DiceSelect';
import DicePresentation from '../components/DicePresentation';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';

export default class DiceView extends React.Component {
    constructor() {
        super();
        this.state = {
            changed: false,
            isHigher: false,
            withAir: false,
        }
    }

    getSelect = (preSelect) => {
        let options = [1, 2, 3, 4, 5, 6].map(number => {
            return <option key={number} value={number}>{number}</option>
        });
        return <select defaultValue={preSelect}>{options}</select>
    }

    sendRoll = (evt) => {
        evt.preventDefault();
        const form = evt.target.parentElement.parentElement;
        let dices = [];
        form.querySelectorAll('select').forEach(
            select => dices.push(select.value)
        )
        this.context.wsHandler.submitRoll(dices, this.state.withAir);
        return false;
    }

    sendReRoll = (evt) => {
        evt.preventDefault();
        this.context.wsHandler.submitReRoll()
        return false;
    }

    onDiceSelectChange = (changed, withAir, isHigher) => {
        if (changed === false) {
            this.setState({
                changed: changed,
                isHigher: false,
                withAir: withAir
            });
        } else {
            this.setState({
                changed: changed,
                isHigher: isHigher,
                withAir: withAir
           });
        }
    }

    getSubmitButtonText = () => {
        const { changed, isHigher } = this.state;
        if (!changed) {
            return "Weiterreichen"
        }
        if (isHigher) {
            return "Lügen"
        }
        return "mit Luft!"
    }

    render() {
        const { dices } = this.context;
        return (
            <View>
                <MessageArea>
                    <h1>Dein Wurf</h1>
                </MessageArea>
                <InfoArea>
                    <DicePresentation dices={ dices } />
                </InfoArea>
                <PlayArea>
                    <ActivePlayerList />
                    <div className="activeField">
                        <form>
                            <DiceSelect preSelect={ dices } onChange={ this.onDiceSelectChange } />
                            <ButtonLine>
                                <Button onClick={ this.sendReRoll } size="small" color="light">Blind neu würfeln</Button>
                                <Button onClick={ this.sendRoll } size="small">{ this.getSubmitButtonText() }</Button>
                            </ButtonLine>
                        </form>
                    </div>
                </PlayArea>
            </View>
        )
    }
}
DiceView.contextType = globalState;
