import React from 'react'
import WebsocketHandler from './WebsocketHandler';

export const initalState = {
    playerName: null,
    playerId: null,
    players: [],
    dices: [0, 0],
};

export const globalState = React.createContext(initalState);

export class StateProvider extends React.Component {
    constructor() {
        super();
        this.state = Object.assign(
            {
                wsHandler: null,
                stateHandler: this
            },
            initalState
       );
    }

    componentDidMount() {
        this.wsHandler = new WebsocketHandler(this)
        this.setState({wsHandler: this.wsHandler })
        this.wsHandler.start();
    }

    render() {
        return (
          <globalState.Provider value={this.state}>
            { this.props.children }
          </globalState.Provider>
        )
    }
}

export const getPlayerBefore = context => {
    if (!context.activePlayer) {
        return null
    }
    const idList = context.players.map(player => player.id);
    let index = idList.indexOf(context.activePlayer.id) - 1;
    if (index < 0) {
        index += context.players.length;
    }
    return context.players[index];
}
