export const InputField = props => {
    return (
        <div className="input-field">
            <label>{ props.label }</label>
            { props.children }
        </div>
    )
}

export default InputField;

