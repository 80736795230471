import React from "react";
import {globalState} from './state';

import NotLoggedIn from './views/NotLoggedIn';
import WaitingView from './views/WaitingView';
import StartView from './views/StartView';
import DiceView from './views/DiceView';
import DecisionView from './views/DecisionView';
import DrinkView from './views/DrinkView';
import AwesomeView from './views/AwesomeView';
import PlayerNameView from './views/PlayerNameView';

export default class PlayRouter extends React.Component {
    componentDidMount()  {
        const { wsActive, wsHandler } = this.context;
        if (wsHandler && !wsActive) {
            wsHandler.start();
        }
    }

    render() {
        if (!this.context) {
            return <NotLoggedIn />
        }
        const { activePlayer, playerId, playerName } = this.context;
        if (playerName === '') {
            return <PlayerNameView />
        }
        if (!activePlayer || activePlayer.id !== playerId) {
            switch (this.context.status) {
                case 'drink':
                    return <DrinkView active={false}/>
                case 'awesome':
                    return <AwesomeView active={false}/>
                default:
                    return <WaitingView />
            }
        }
        switch (this.context.status) {
            case 'gameStart':
                return <StartView />
            case 'showDice':
                return <DiceView />
            case 'decision':
                return <DecisionView />
            case 'drink':
                return <DrinkView active={true} />
            case 'awesome':
                return <AwesomeView active={true} />
            default:
                return "unknown state: " + this.status;
        }
    }
}

PlayRouter.contextType = globalState;
