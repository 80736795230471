import React from 'react';

import './Background.css';


function Bubble(props) {
    const repeat = props.repeat? props.repeat: 1;
    const circles = [];
    for (let i=0; i<repeat; i++) {
        circles.push(
            <circle
                key={i}
                cx={ props.radius + 2 + ( -(props.radius * 2) * i % 2 * -1 ) }
                cy={ props.radius + 2 + ( i * (props.radius * 2 + props.radius)) }
                r={ props.radius}
                stroke="none"
                strokeWidth="1"
                fill="rgba(225, 255, 255, 0.5)"
                // fill="black"
            />
        )
    }
    return (
        <svg className={"background-bubble speed"+props.speed }
          height={ (props.radius * 2 + 2) * props.repeat }
          width={ props.radius * 2 + 4}
          style={ { left: props.x } }>
            { circles }

        </svg>
    )
}



export default class Background extends React.Component {
    maxBubbles = 10;

    constructor(props) {
        super(props);
        this.state = {
            bubbles: {}
        }
    }

    componentDidMount() {
        this.addBubble();
        this.check = setTimeout(this.checkBubbles, (Math.random() * 500 + 500));
    }

    componentWillUnmount() {
        clearTimeout(this.check);
    }

    checkBubbles = () => {
        const toRemove = []
        const now = new Date().getTime();
        for (let key in  this.state.bubbles) {
            if (now > this.state.bubbles[key].ttl) {
                toRemove.push(key);
            }
        }
        if (toRemove.length) {
            this.removeBubbles(toRemove);
        } else {
            this.fillBubbles()
        }
        this.check = setTimeout(this.checkBubbles, (Math.random() * 300 + 300));
    }

    fillBubbles = () => {
        const count =  Object.keys(this.state.bubbles).length;
        if (count < this.maxBubbles) {
            this.addBubble();
        }
    }

    removeBubbles = toRemove => {
        this.setState(prev => {
            const bubbles = {};
            for (let key in prev.bubbles) {
                if (toRemove.indexOf(key) === -1) {
                    bubbles[key] = prev.bubbles[key];
                }
            }
            return { bubbles: bubbles }
        }, this.fillBubbles)
    }


    addBubble = () => {
        this.setState(prev => {
            const ttl = new Date().getTime() + 9000;
            const key = Math.round(Math.random() * 10000000);
            const newBubbles = Object.assign({} , prev.bubbles);
            const radius = Math.random() * 4;
            const repeat = Math.random() * 50;
            const x = Math.random() * window.innerWidth;
            const speed = Math.max(1, Math.min(Math.round(Math.random() * 6) + 1, 6));
            newBubbles[key] = {ttl, radius, repeat, x, speed};
            return { bubbles: newBubbles };
        })
    }


    render() {
        const columns = [];
        for (let key in this.state.bubbles) {
            const { radius, x, repeat, speed } = this.state.bubbles[key];
            columns.push(<Bubble key={key} radius={radius} x={ x } repeat={ repeat } speed={ speed }/>);
        };
        return (
            <div className='background'>
                { columns }
            </div>
        )
    }
}
