import React from "react";
import './Button.css';

export default class Button extends React.Component {
    render() {
        const { className, color, size } = this.props;
        let classes = ["button", className];
        classes.push(color? color:"");
        classes.push(size?size:"big");
        const classString = classes.join(" ");
        return (
            <button className={classString} onClick={ this.props.onClick } disabled={ this.props.disabled } style={ this.props.style }>
                { this.props.children }
            </button>
        )
    }
}
