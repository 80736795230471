import React from 'react';
import { globalState, getPlayerBefore } from '../state';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';
import DicePresentation from '../components/DicePresentation';

import blindImg from '../img/blind.svg';
import airImg from '../img/air.svg';
import waitingImg from '../img/waiting.svg';

export default class WaitingView extends React.Component {
    render() {
        const { activePlayer, visibleDices, stateHandler, wsHandler, playerName, withAir, isReroll } = this.context;
        const playerBefore = getPlayerBefore(this.context);
        if (!activePlayer) {
            return "Hmm der, der gerade dran ist, ist grade offline..."
        }
        const noDicesYet = visibleDices[0] === 0 && visibleDices[1] === 0;
        if (noDicesYet) {
            return (
                <View>
                    <MessageArea>
                        <h1>Machs dir Bequem</h1>
                    </MessageArea>
                    <InfoArea>
                        <img src={ waitingImg } alt="Prost!" />
                    </InfoArea>
                    <PlayArea>
                         <ActivePlayerList />
                         <h1>Hi { playerName },</h1>
                         <h2>
                             { activePlayer.name } muss das Spiel starten. Stell dir doch schon
                             mal was leckers bereit!
                         </h2>
                         <ButtonLine>
                             <Button onClick={ () => stateHandler.setState({playerName: ''})} size="small" color="light">
                                 Meinen Namen ändern
                             </Button>
                             <Button onClick={() => wsHandler.requestAction(false)} size="small">Stressen</Button>
                         </ButtonLine>
                    </PlayArea>
                </View>
            )
        }
        return (
            <View>
                <MessageArea>
                    <h1>
                        { "Wurf von " + playerBefore.name }
                        { isReroll? [<br key="_" />, '- Blind -'] : ''}
                        { withAir? [<br key="_" />, '- mit Luft -'] : ''}
                    </h1>
                </MessageArea>
                <InfoArea>
                    { isReroll? <img src={ blindImg } alt="Blind" className="blind-roll" />: ''}
                    { withAir? <img src={ airImg } alt="mit Luft" className="with-air" />: ''}
                    <DicePresentation dices={ visibleDices } />
                </InfoArea>
                <PlayArea>
                    <ActivePlayerList />
                    <div className="activeField">
                        <br />
                        <h2>Ja { activePlayer.name } braucht mal wieder Lange, und?</h2>
                        <ButtonLine>
                            <Button onClick={ () => stateHandler.setState({playerName: ''})} size="small" color="light">
                                Meinen Namen ändern
                            </Button>
                            <Button onClick={() => wsHandler.requestAction(false)} size="small">Stressen</Button>
                        </ButtonLine>
                    </div>
                </PlayArea>
            </View>
        )
    }
}

WaitingView.contextType = globalState;
