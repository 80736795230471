import Dice from './Dice';

import "./DicePresentation.css";

export default  function DicePresentation(props) {
    return (
        <div className="dicePresentation">
            <div className="diceItem">
                <Dice size="big" value={ props.dices[0] } />
             </div>
            <div className="diceItem">
                <Dice size="big" value={ props.dices[1] } />
            </div>
        </div>
    )
}
