import React from 'react';
import { globalState } from '../state';
import Carousel from './Carousel';

export default class ActivePlayers extends React.Component {
    render() {
        const { players, activePlayer } = this.context;
        return <Carousel items={ players } selected={activePlayer} />
    }
}

ActivePlayers.contextType = globalState;
