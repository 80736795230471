import { useContext } from 'react';
import { globalState } from '../state';

import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';

import cheersImg from '../img/cheers.svg';

export default function StartView() {
    const { stateHandler, wsHandler} = useContext(globalState);
    return (
        <View>
            <MessageArea>
                <h1>Worauf wartest du?</h1>
            </MessageArea>
            <InfoArea>
                <img src={ cheersImg } alt="Prost!" />
            </InfoArea>
            <PlayArea>
                <ActivePlayerList />
                <div className="activeField">
                    <h2>Alle warten auf dich!</h2>
                    <p>was ein geiles Gefühl!</p>
                    <br /><br /><br />
                    <ButtonLine>
                        <Button onClick={ () => stateHandler.setState({playerName: ''})} size="small" color="light">
                            Meinen Namen ändern
                        </Button>
                        <Button onClick={ wsHandler.rollDice } size="small">Runde starten</Button>
                    </ButtonLine>
                </div>
            </PlayArea>
        </View>
    )
}

