import React from 'react';
import { globalState } from '../state';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';
import DicePresentation from '../components/DicePresentation';

export default class DrinkView extends React.Component {
    renderActiveField = (active) => {
        if (active) {
            return (
                <div className="activeField">
                    <br />
                    <h1>Lass es dir schmecken. Die sagen wenns reicht</h1>
                    <br />
                    <ButtonLine>
                        <Button onClick={ () => this.context.wsHandler.requestAction(true) }>
                            Das war definitiv genug
                        </Button>
                    </ButtonLine>
                </div>
            )
        }
        return (
            <div className="activeField">
                <br />
                <h1> Hat { this.context.activePlayer.name } genug getrunken?</h1>
                <br />
                <ButtonLine>
                    <Button onClick={ () => {this.context.wsHandler.confirmDrink()} }>Jo das war genug</Button>
                </ButtonLine>
            </div>
        )
    }


    render() {
        if (!this.context.activePlayer) {
            return "Der Trinker is offline... Wenn er nicht zurückkommt muss der nächste Trinken..."
        }
        const active = this.props.active;
        const { dices, activePlayer } = this.context;
        return (
            <View>
                <MessageArea>
                   <h1>{ active ? "Tja dumm gelaufen:": activePlayer.name + " muss trinken:" }</h1>
                </MessageArea>
                <InfoArea>
                    <DicePresentation dices={ dices } />
                </InfoArea>
                <PlayArea shake={ active }>
                    <ActivePlayerList />
                    { this.renderActiveField(active) }
                </PlayArea>
            </View>
        )
    }
}

DrinkView.contextType = globalState;
