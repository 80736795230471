import React from 'react';
import "./Dice.css";

import dice1 from '../img/dice1.svg';
import dice2 from '../img/dice2.svg';
import dice3 from '../img/dice3.svg';
import dice4 from '../img/dice4.svg';
import dice5 from '../img/dice5.svg';
import dice6 from '../img/dice6.svg';
import dice_unknown from '../img/dice_unknown.svg';

export default class DecisionView extends React.Component {
    render() {
        const { size, value, active, onClick, preSelect } = this.props;
        const diceSvg = {
            1: dice1,
            2: dice2,
            3: dice3,
            4: dice4,
            5: dice5,
            6: dice6,
            '?': dice_unknown,
        }
        const dice = diceSvg[value];
        if (!dice) {
            return "?";
        }
        const classes = "dice" + (size? " " + size : "") + (active? " active" : "") + (preSelect? " preSelect": "");
        return (
            <img src={ dice } alt={value} className={classes} onClick={ onClick}  />
        )
    }
}

