import React from 'react';
import Dice from '../components/Dice';
import { globalState } from '../state';

import './DiceSelect.css';

export default class DiceSelect extends React.Component {
    constructor(props) {
        super();
        this.state = {
            first: props.preSelect[0],
            second: props.preSelect[1],
            withAir: false,
        }
    }

    onDiceSelect = (number, position) => {
        let update = {};
        update[position] = number;
        this.setState(update);
        let changed = false
        if (position === 'first') {
            changed = (
                number !== this.props.preSelect[0] ||
                this.state.second !== this.props.preSelect[1]
            )
        } else {
            changed = (
                this.state.first !== this.props.preSelect[0] ||
                number !== this.props.preSelect[1]
            )
        }
        if (this.props.onChange) {
            const dices = this.getSortedState(number, position);
            const isHigher = this.isHigher(dices);
            this.setState({ withAir: (changed && ! isHigher) }, () => {
                this.props.onChange(changed, this.state.withAir, isHigher);
            });
        }
    }

    onWithAirChange = () => {
        let dices = [this.state.first, this.state.second]
        if (dices[1] > dices[0]) {
            dices = [dices[1], dices[0]]
        }
        this.setState(prev => { return { withAir: !prev.withAir}}, () => {
            this.props.onChange(false, dices, this.state.withAir, this.isHigher(dices));
        });
    }

    getSortedState = (number, position) => {
        let { first, second } = this.state;
        if (position === 'first') {
            first = number
        } else {
            second = number
        }
        if (first >= second) {
            return [first, second];
        } else {
            return [second, first]
        }
    }

    isHigher = (dices) => {
        const truth = this.context.dices;
        if (truth[0] === 2 && truth[1] === 1) {
            return false;
        }
        if (dices[0] === 2 && dices[1] === 1) {
            return true;
        }
        if (truth[0] === truth[1]) {
            if (dices[0] !== dices[1]) {
                return false;
            }
            return dices[0] > truth[0];
        }
        if (dices[0] === dices[1]) {
            return true;
        }
        if (truth[0] > dices[0]) {
            return false;
        }
        if (dices[0] > truth[0]) {
            return true
        }
        return dices[1] > truth[1];
    }


    renderDice = (number, position) => {
        const dices = this.props.preSelect;
        const truth = position === "first"?dices[0]: dices[1];
        return (
            <Dice
                value={number}
                active={ this.state[position] === number }
                preSelect={ truth === number }
                onClick={() => this.onDiceSelect(number, position)} />
        )
    }

    render() {
        return (
            <div className="DiceSelect">
                <div className="DiceOne">
                    <span className="row">
                        { this.renderDice(1, "first") }
                        { this.renderDice(2, "first") }
                        { this.renderDice(3, "first") }
                     </span>
                    <span className="row">
                        { this.renderDice(4, "first") }
                        { this.renderDice(5, "first") }
                        { this.renderDice(6, "first") }
                     </span>
                </div>
                <div className="withAir"
                  style={ { color: this.state.withAir? 'green' : 'black'  } }
                  onClick={ () => this.onWithAirChange() } >
                    mit Luft
                </div>
                <div className="DiceTwo">
                    <span className="row">
                        { this.renderDice(1, "second") }
                        { this.renderDice(2, "second") }
                        { this.renderDice(3, "second") }
                     </span>
                    <span className="row">
                        { this.renderDice(4, "second") }
                        { this.renderDice(5, "second") }
                        { this.renderDice(6, "second") }
                     </span>
                </div>
                <select style={ { display: "none" }}>
                   <option value={ this.state.first } />
                </select>
                <select style={ { display: "none" }}>
                   <option value={ this.state.second } />
                </select>
            </div>
         )
    }
}

DiceSelect.contextType = globalState;
