import React from 'react';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import Button from '../components/Button';
import { withRouter } from 'react-router-dom';

import { globalState } from '../state';

import './WelcomeView.css';

class WelcomeView extends React.Component {
    copyLink = (link) => {
        const dummy = document.createElement("textarea");
        dummy.style.position = "fixed";
        dummy.style.opacity = "0";
        dummy.textContent = link;
        document.body.appendChild(dummy);
        dummy.select();
        dummy.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }

    startGame = id => {
        this.props.history.push('/'+id);
    }

    render() {
        const id = Math.random().toString(16).substr(2, 8)
        const loc = window.location;
        const link = loc.protocol + "//" + loc.host + "/" + id;
        return (
            <View className='welcome-view'>
                <MessageArea>
                    <h1>Es hat viele Namen</h1>
                    <span>Doch eins haben haben Sie alle gemeinsam</span>
                </MessageArea>
                <InfoArea>
                    <div className="game-aliases">
                        Mäxchen<br />
                        Maiern<br />
                        Lügenwürfeln
                    </div>
                    <div className="prost">
                        Prost!
                    </div>
                </InfoArea>
                <PlayArea>
                    <div className="info-text">
                        <h1>Starte dein Spiel</h1>
                        <div>
                            Dein Tisch hat die ID: { id }
                        </div>
                    </div>
                    <ButtonLine>
                        <Button size="small" color="light" onClick={() => this.copyLink(link) }>Link kopieren</Button>
                        <Button size="small" onClick={ () => { this.startGame(id) } }>An den Tisch setzen</Button>
                    </ButtonLine>
                </PlayArea>
            </View>
        )
    }
}

WelcomeView.contextType = globalState;

export default withRouter(WelcomeView);
