import React from 'react';

import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';
import InputField from '../components/InputField';

import { globalState } from '../state';

import drinkmanImg from '../img/drinkman.svg';

import './PlayerNameView.css';

export default class PlayerNameView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {'name': ''}
        this.domElement = React.createRef();
    }

    componentDidMount = () => {
        this.domElement.current.focus();
        this.domElement.current.addEventListener('keyup', evt => {
            if (evt.keyCode === 13) {
                this.changeName();
            }
        });
        this.domElement.current.addEventListener('focusout', () => {
            this.changeName();
        });
    }

    changeName = () => {
        this.context.wsHandler.send({
            'type': 'nameChanged',
            'value': this.state.name
        });
    }

    onInput = evt => {
        this.setState({name: evt.target.value});
    }

    render() {
        return (
            <View>
                <MessageArea>
                     <h1>Gib dir einen Namen</h1>
                </MessageArea>
                <InfoArea>
                    <img src={ drinkmanImg } alt=""/>
                </InfoArea>
                <PlayArea>
                    <ActivePlayerList />
                    <div className="activeField">
                        <InputField label="Wer trinkt denn hier?">
                            <input
                                id="player-name-input"
                                ref={this.domElement}
                                className="player-name-input"
                                onChange={this.onInput}
                                type="text"
                                value={this.state.name}
                                maxlength="16" />
                        </InputField>
                        <ButtonLine>
                            <Button onClick={this.changeName}>Los Gehts</Button>
                        </ButtonLine>
                    </div>
                </PlayArea>
            </View>
        )
    }
}

PlayerNameView.contextType = globalState;
