import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.css';
import { StateProvider } from './state';
import PlayRouter from './PlayRouter';
import WelcomeView from './views/WelcomeView';
import Background from './components/Background';

function App() {
  return (
    <div className="App">
        <Background />
        <StateProvider>
            <div className="App-content">
                <BrowserRouter>
                    <Switch>
                        <Route path="/:room_id">
                            <PlayRouter />
                        </Route>
                        <Route path="/">
                            <WelcomeView />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        </StateProvider>
    </div>
  );
}


export default App;
