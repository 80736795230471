import React from 'react';

import "./Shaker.css";

import BellSound from '../sound/bell.aac';

export default class Shaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shake: false
        }
        this.timeout = null;
    }

    componentDidMount() {
        this.toggleShake(this.props.shake? true: false)
        this.listener = document.addEventListener('actionRequested', this.startShake);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shake !== this.props.shake) {
            this.toggleShake(this.props.shake? true: false);
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        document.removeEventListener('actionRequested', this.startShake);
    }

    stopShake = () => {
        this.setState({shake: false});
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    startShake = () => {
        const sound = new Audio(BellSound);
        sound.play();
        this.timeout = setTimeout(this.stopShake, 500);
        this.setState({shake: true});
    }

    toggleShake = (shake) => {
        if (shake) {
            this.startShake();
        } else {
            this.stopShake();
        }
    }

    render() {
        let classes = "shaker";
        if (this.state.shake) {
            classes += " shake";
        }
        return (
            <div className={classes}>
                {this.props.children}
            </div>
        )
    }
}
