import React from 'react';
import { globalState, getPlayerBefore } from '../state';
import { View, MessageArea, InfoArea, PlayArea, ButtonLine } from '../components/ViewParts';
import ActivePlayerList from '../components/ActivePlayers';
import Button from '../components/Button';
import DicePresentation from '../components/DicePresentation';

import blindImg from '../img/blind.svg';
import airImg from '../img/air.svg';

import './DecisionView.css';

export default class DecisionView extends React.Component {

    renderMessage = () => {
        const before = getPlayerBefore(this.context);
        return (
            <MessageArea>
                <h1>
                    { "Wurf von " + before.name }
                    { this.context.isReroll? [<br key="_" />, '- Blind -'] : ''}
                    { this.context.withAir? [<br key="_" />, '- mit Luft -'] : ''}
                </h1>
            </MessageArea>
        )
    }

    render() {
        const { wsHandler, visibleDices } = this.context;
        return (
            <View>
                { this.renderMessage() }
                <InfoArea>
                    { this.context.isReroll? <img src={ blindImg } alt="Blind" className="blind-roll" />: ''}
                    { this.context.withAir? <img src={ airImg } alt="mit Luft" className="with-air" />: ''}
                    <DicePresentation dices={ visibleDices } />
                </InfoArea>
                <PlayArea shake={ true } >
                    <ActivePlayerList />
                    <div className="activeField">
                        <h2>Was willst du machen?</h2>
                        <br />
                        <ButtonLine>
                             <Button size="small" color="red" onClick={wsHandler.showDice}>
                                Aufdecken
                             </Button>
                             { this.context.withAir?
                                <Button size="small" onClick={wsHandler.higher}>weiter mit Luft</Button>
                              : <Button size="small" onClick={wsHandler.rollDice}>Glauben und Würfeln</Button>
                             }
                             { this.context.withAir?
                                <Button size="small" color="light" onClick={wsHandler.rollDice}>Selber würfeln</Button>
                                : ""
                             }
                        </ButtonLine>
                    </div>
                </PlayArea>
            </View>
        )
    }
}

DecisionView.contextType = globalState;
